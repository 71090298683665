@mixin phoneLandscape{
    @media only screen and (orientation: landscape)
    {
        @content;
    }
}

@mixin tablet{

    @media only screen and (min-width: $tablet-min-width) and (orientation: landscape){

        @content;

    } 

}

@mixin tabletPortrait{

    @media only screen and (min-width: $tablet-min-width) and (orientation: portrait){

        @content;

    } 

}

@mixin desktop{

    @media only screen and (min-width: $desktop-min-width) and (orientation: landscape){

        @content;

    } 
    
}

@mixin large{

    @media only screen and (min-width: $large-min-width) and (orientation: landscape){

        @content;

    } 
    
}

@mixin x-large{

    @media only screen and (min-width: $xl-min-width){

        @content;

    } 
    
}