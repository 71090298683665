.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.none {
  display: none;
}

.show-from---t {
  display: none !important;
}
.show-from---d {
  display: none !important;
}
.show-from---xl {
  display: none !important;
}

@include tablet {
  .show-from---t {
    display: block !important;
  }
  .hide-from---t {
    display: none !important;
  }
}

@include desktop {
  .show-from---d {
    display: block !important;
  }
  .hide-from---d {
    display: none !important;
  }
}

@include large {
  .show-from---xl {
    display: block !important;
  }
  .hide-from---xl {
    display: none !important;
  }
}
