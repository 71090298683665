@import "./themes.scss";
@import "./theme-variables";
@import "./fonts";

body,
html,
#root {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    background-color: var(--colour-secondary);
}

.page {
    width: 100%;
    min-height: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--colour-tertiary);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: all 0.2s ease;
}

.zoomPage {
    overflow: hidden;
}

.blur {
    filter: blur($blur-factor);
    transition: all 0.2s ease;
    overflow: hidden;
}

*,
:after,
:before {
    box-sizing: border-box;
}

p {
    font-family: "Nunito";
    font-weight: 400;
    font-size: $bodyText2FontSize;
    line-height: $bodyText2LineHeight;
    color: var(--text-colour-primary);
}

h1 {
    font-family: "Nunito";
    font-weight: 800;
    font-size: $titleText2FontSize;
    line-height: $titleText2LineHeight;
    color: var(--text-colour-primary);
}

a {
    text-decoration: none;
}

textarea {
    width: 100%;
    max-width: 100%;
}

#nprogress .bar {
    background: #ff3fb4 !important;
}

.bodyText1 {
    font-family: "Nunito";
    font-weight: 400;
    font-size: $bodyText1FontSize;
    line-height: $bodyText1LineHeight;
    color: var(--text-colour-primary);
}

.bodyText2 {
    font-family: "Nunito";
    font-weight: 400;
    font-size: $bodyText2FontSize;
    line-height: $bodyText2LineHeight;
    color: var(--text-colour-primary);
}

.subText1 {
    font-family: "Nunito";
    font-weight: 400;
    font-size: $subText1FontSize;
    line-height: $subText1LineHeight;
    color: var(--text-colour-primary);
}

.titleText2 {
    font-family: "Nunito";
    font-weight: 700;
    font-size: $titleText2FontSize;
    line-height: $titleText2LineHeight;
    color: var(--text-colour-primary);
}

@media only screen and (min-width: 1080px) {
    .zoomPage {
        overflow-y: auto;
    }

    .bodyText1 {
        font-size: $bodyText1FontSize * 1.5;
        line-height: $bodyText1LineHeight * 1.5;
    }

    .bodyText2 {
        font-size: $bodyText2FontSize * 1.5;
        line-height: $bodyText2LineHeight * 1.5;
    }

    .subText1 {
        font-size: $subText1FontSize * 1.5;
        line-height: $subText1LineHeight * 1.5;
    }

    .titleText2 {
        font-size: $titleText2FontSize * 1.5;
        line-height: $titleText2LineHeight * 1.5;
    }
}
