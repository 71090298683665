// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;0,800;1,400&display=swap');

@font-face {
    font-family: "Gotham Black";
    src: url("/assets/fonts/GothamBlack/Gotham-Black.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    src: url("/assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    src: url("/assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    src: url("/assets/fonts/Nunito/Nunito-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Nunito";
    src: url("/assets/fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Nunito";
    src: url("/assets/fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "OctinCollegeHv";
    src: url("/assets/fonts/OctinCollegeHv/OctinCollegeHv-Regular.ttf") format("truetype");
    font-weight: 400;
}