@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button-loader {
    margin:auto;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-left: 2px solid;
    animation: load 1s infinite linear;
    border-radius: 100%;
    width: 15px;
    height: 15px;
}