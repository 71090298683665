@import './styles/scss/variables/variables.scss';
@import './styles/scss/mixins/mixins.scss';
@import './styles/scss/layout/display.scss';
@import './styles/scss/base/base.scss';
@import './styles/scss/components/components.scss';

html {
  font-size: 62.5%;
  @include font-regular;
  height: 100%;
}

input,
button,
select,
option {
  @include font-regular;
}

input {
  border-radius: 0;
}

body {
    background: white;
    margin: 0;
    padding: 0;
    color: white; 
    max-width: 1920px !important;
    margin-left: auto;
    margin-right: auto;
    
  }
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @include font-bold;
  font-size: 1.6rem;
  font-weight: bold;
}

h2 {
  @include font-bold;
  font-size: 1.4rem;
  font-weight: bold;
}

h3 {
  @include font-bold;
  font-size: 1.2rem;
  font-weight: bold;
}

h4 {
  @include font-bold;
  font-size: 1.2rem;
  font-weight: bold;
}

p {
  @include font-regular;
  font-size: 1.4rem;
}

.regular-text {
  @include font-regular;
}

.bold-text {
  @include font-bold;
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
  h1 {
      @include font-bold;
      font-size: 1.4rem;
      font-weight: bold;
  }

  h2 {
      @include font-bold;
      font-size: 1.2rem;
      font-weight: bold;
  }

  h3 {
      @include font-bold;
      font-size: 1.1rem;
      font-weight: bold;
  }

  h4 {
      @include font-bold;
      font-size: 1rem;
      font-weight: bold;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  h1 {
      @include font-bold;
      font-size: 1.6rem;
      font-weight: bold;
  }

  h2 {
      @include font-bold;
      font-size: 1.4rem;
      font-weight: bold;
  }

  h3 {
      @include font-bold;
      font-size: 1.2rem;
      font-weight: bold;
  }

  h4 {
      @include font-bold;
      font-size: 1.1rem;
      font-weight: bold;
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  h1 {
      @include font-bold;
      font-size: 2.2rem;
      font-weight: bold;
  }

  h2 {
      @include font-bold;
      font-size: 2.0rem;
      font-weight: bold;
  }

  h3 {
      @include font-bold;
      font-size: 1.8rem;
      font-weight: bold;
  }

  h4 {
      @include font-bold;
      font-size: 1.4rem;
      font-weight: bold;
  }
}

@media only screen and (min-width: 768px) and (max-height: 768px) and (orientation: landscape) {
  .call-to-action {
      font-size: 2.4rem;
  }
}

@media only screen and (min-width: 1366px) and (max-height: 1024px) {
  h1 {
      @include font-bold;
      font-size: 2.7rem;
      font-weight: bold;
  }

  h2 {
      @include font-bold;
      font-size: 2.6rem;
      font-weight: bold;
  }

  h3 {
      @include font-bold;
      font-size: 2.2rem;
      font-weight: bold;
  }

  h4 {
      @include font-bold;
      font-size: 1.4rem;
      font-weight: bold;
  }
}