@import "./theme-variables.scss";
@import "./fonts";

.errorContainer {
    width: 100%;
    height: 100vh;
    font-family: "Helvetica Now Text", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    padding-top: 15%;
    color: black;
}

.errorPageImage {
    width: 128px;
}

.errorContainer > h1 {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 48px;
    line-height: 48px;
    margin: 0;
    color: black;
}

.errorContainer > h2 {
    font-weight: 400;
    font-size: 36px;
    margin: 0;
    color: black;
}

.errorContainer > div {
    margin-top: 10px;
    color: black; 
}

.errorContainer a {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}

@media only screen and (max-width:1024px) {
    .errorContainer {       
        align-items: center;
        justify-content: center;
        padding: 16px;
    }

    .errorPageImage {
        width: 128px;
    }
    
    .errorContainer > h1 {
        font-size: 36px;
        line-height: 36px;
        color: black;
    }

    .errorContainer > h2 {
        font-size: 24px;
        color: black;
    }

    .errorContainer > div {
        margin-top: 5px;
        margin-bottom: 36px;    
        color: black;
    }
}

@media only screen and (orientation: landscape) {
    .errorContainer {       
        padding-top: 0;
        justify-content: center;
        align-items: center;
    }
}
