$font-stack: "Nunito", sans-serif;
$font-text: "Nunito", sans-serif;
$html_font-size: 62.5%;
$body-font-size: 1.8rem;
$default-font-color: white;

@mixin font-regular {
  font-family: $font-text;
  font-weight: normal;
}

@mixin font-regular-bold {
  font-family: $font-text;
  font-weight: bold;
}

@mixin font-regular-italic {
  font-family: $font-text;
  font-weight: normal;
  font-style: italic;
}

@mixin font-medium {
  font-family: $font-text;
  font-weight: 500;
}

@mixin font-medium-bold {
  font-family: $font-text;
  font-weight: bold;
}
@mixin font-medium-italic {
  font-family: $font-text;
  font-style: italic;
}
@mixin font-bold {
  font-family: $font-text;
  font-weight: bold;
}

@mixin font-bold-italic {
  font-family: $font-text;
  font-weight: bold;
  font-style: italic;
}

@mixin font-black {
    font-family: $font-text;
    font-weight: 900;
}
