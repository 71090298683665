$themes: (
    light: (
        'colour-primary': #C6C6C6,
        'colour-secondary':#EFEFEF,
        'colour-tertiary': #ffffff,
        'colour-box-shadow': #00000029,
        'colour-selected': #130a6c,
        'navbar-color':#041E3A,
        'load-from': #EFEFEF,
        'load-to-banner': darken(#EFEFEF, 6%),
        'load-to': darken(#EFEFEF, 4%),
        'load-from-navy': lighten(#041E3A, 6%),
        'load-to-navy': #041E3A,
        'text-colour-primary': #383838,
        'text-colour-secondary': #717171,
        'text-colour-tertiary': #8F8F8F,
        'text-colour-heading': #1A2578,
        'text-colour-navbar': #FFFFFF,
        'border-colour-primary': #ADB6F9,
        'unavailable-background-colour': #80808036,
        'button-border': none,
        'button-background-colour': #C21E2E,
        'button-text-colour': #FFFFFF,
        'cancel-button-background': #EFEFEF,
        'cancel-button-text-colour': #041E3A,
        'colour-swatch-background': #FFFFFF,
        'input-border-colour': #041E3A,
        'radio-input-border-colour': #041E3A,
        'radio-input-background-colour': #041E3A,
        'snackbar-background': #323232,
        'snackbar-text': #EFEFEF,
        'pdp-edit-button-filter': unset
    ),
    dark: (
        'colour-primary': #000000,
        'colour-secondary':#000000,
        'colour-tertiary': #FFFFFF,
        'colour-box-shadow': #00000029,
        'colour-selected': #FFFFFF,
        'navbar-color':#000000,
        'load-from': #8b8b8b,
        'load-to-banner': darken(#242424, 6%),
        'load-to': darken(#242424, 4%),
        'load-from-navy': lighten(#041E3A, 6%),
        'load-to-navy': #041E3A,
        'text-colour-primary': #FFFFFF,
        'text-colour-secondary': #717171,
        'text-colour-navbar': #FFFFFF,
        'border-colour-primary': #ADB6F9,
        'button-border': 1px solid #FFFFFF,
        'button-background-colour': #C21E2E,
        'button-text-colour': #FFFFFF,
        'cancel-button-background': #FFFFFF,
        'cancel-button-text-colour': #000000,
        'colour-swatch-background': #000000,
        'input-border-colour': #FFFFFF,
        'radio-input-border-colour': #FFFFFF,
        'radio-input-background-colour': #FFFFFF,
        'snackbar-background': #FFFFFF,
        'snackbar-text': #000000,
        'pdp-edit-button-filter': invert(100%)
    )
);

@each $name, $map in $themes {
    .theme-#{$name} {
        @each $key, $value in $map {
            --#{$key}: #{$value};
        }
    }
}