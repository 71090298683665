@import "./theme-variables";

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-left: 2px solid;
    animation: load 1s infinite linear;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    color: var(--colour-primary);
}

.loader-container {
    position: absolute;
    z-index:100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
